import { Component, OnInit, OnDestroy } from '@angular/core';


@Component({
    selector: 'site-home',
    templateUrl: 'home.component.html',
    styleUrls: []
})
export class HomeComponent implements OnInit {
 
    constructor (
    ) { }

    ngOnInit() {

    }
  
}